<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <td colspan="2"></td>
          <td colspan="4">July</td>
          <td colspan="4">August</td>
        </tr>
        <tr>
          <th>Budget Head</th>
          <th>Account Head</th>
          <th>Budget</th>
          <th>Actual</th>
          <th>Variance</th>
          <th>% of Budget</th>
          <th>Budget</th>
          <th>Actual</th>
          <th>Variance</th>
          <th>% of Budget</th>
        </tr>
        </thead>
        <tbody>
        <td>
          <input class="form-control text-end" placeholder="Budget Head"/>
        </td>
        <td>
          <input class="form-control text-end" placeholder="Account Head"/>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
