<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton :showBtn="false" :showAddNew="false" title="Budget vs Actual Report"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <input class="form-control" placeholder="Budget 2022-23">
        </div>
        <div class="col-12 col-lg-12 col-xl-12 col-xxl-12">
          <DateQuerySetter @onClickGo="getBudgetActuals"/>
        </div>
      </div>
    </div>

    <BudgetInfo/>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/budget/report/budget-actual/BudgetActualTable'
import BudgetInfo from '@/components/molecule/procurement/budget/report/BudgetInfo.vue'
import Pagination from '@/components/atom/Pagination'

function onClickAddNewButton() {
  //todo
}

function getBudgetActuals() {
  //todo
}

function onPageChange() {
  //todo
}

</script>
